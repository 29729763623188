import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getPrintingRecheckList,
  updateConfig,
} from "../../../store/slices/Printing/printingSlice";
import { startTimerRecheck } from "../api";
import { toast } from "react-toastify";

const usePrintingRecheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const visitingOneFrontPrintRef2 = useRef();
  const visitingOneBackPrintRef = useRef();
  const papperFrontPrintRef = useRef();
  const papperBackPrintRef = useRef();
  const bigFrontPrintRef = useRef();
  const vistingDuplexRef = useRef();
  const papperDuplexRef = useRef();
  const foldableCardRef = useRef(null);
  const dispatch = useDispatch();
  const printingState = useSelector((state) => state.printing);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: 10,
      process_slug: slug,
    };
    dispatch(getPrintingRecheckList(params));
    // eslint-disable-next-line
  }, [pendingPage, printingState.currentPageSize]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    dispatch(updateConfig((state) => (state.searchRecheck = searchValue)));
  };

  const handleSearch = () => {
    const searchValue = printingState?.searchRecheck;
    dispatch(
      updateConfig((state) => {
        state.cardRecheckData.Pending = [];
      })
    );
    dispatch(
      getPrintingRecheckList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
  };

  const startTimerPrinting = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimerRecheck(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    startTimerPrinting(e);
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = [e?._id];
        state.gli_id = e?.gli_number;
        state.currentRecheckPage = 1;
        state.isMultiPrint = false;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.printModal = !state.printModal;
      })
    );
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      printingState?.cardRecheckData?.Pending?.length !==
        printingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  const handleMultiClick = (status) => {
    dispatch(
      updateConfig((state) => {
        state.isMultiGliPrint = !state.isMultiGliPrint;
        state.isMultiPrint = status ? true : false;
      })
    );
  };
  return {
    visitingOneFrontPrintRef2,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    vistingDuplexRef,
    papperDuplexRef,
    foldableCardRef,
    cardRecheckData: printingState?.cardRecheckData,
    totalLength: { Pending: printingState.total.Pending },
    printingState,
    apiStatus: { Pending: printingState?.RecheckPendingCardStatus },
    isLoading,
    activeCard,
    handleSearchInput,
    handleSearch,
    setIsLoading,
    handleCardClick,
    handleMultiClick,
    handleLoadMore,
    handleModalClick,
  };
};

export default usePrintingRecheck;
