import React from "react";
import Select from "react-select";
import CardHero from "../CardHero";
import {
  DatePickerWithInput,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../../Global/EmptyData";
import useShopReport from "./useShopReport";
import { FaSort } from "react-icons/fa";
import Style from "../../report.module.scss";

const ShopReport = ({ basicData }) => {
  const {
    date,
    formik,
    cardData,
    isFetching,
    reportState,
    branchReportData,
    paginationOptions,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleDateChange,
    handleBranchFilter,
    handleDownloadClick
  } = useShopReport();
  return (
    <div className="col-auto pro-pt-2 pro-pb-6">
      <div>
        <div className="col-12">
          <div className="row gx-2 gy-2">
            <div className={`col-6 col-lg-4 col-xxl-2 ${Style.pt_24}`}>
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  placeholder="Search this table"
                  className={`pro-input icon-r lg`}
                  value={reportState?.otherReport?.search}
                />
                <span className="pro-icon">
                  {<span className="material-symbols-outlined">search</span>}
                </span>
              </div>
            </div>
            <div className={`col-6 col-lg-4 col-xxl-2 ${Style.pt_24}`}>
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range={true}
                initialValue={date}
                onDateChange={handleDateChange}
              ></DatePickerWithInput>
            </div>
            {/* <div className="col-6 col-lg-4 col-xxl-2">
              <label
                htmlFor="branch"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Branch
              </label>
              <Select
                id="branch"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                name="branch"
                options={basicData?.branch_list}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.branch_name}
                value={formik?.values?.branch}
                onChange={(value) => formik.setFieldValue("branch", value)}
                menuPlacement="auto"
              />
            </div> */}
            <div className="col-6 col-lg-4 col-xxl-2">
              <div className="input-wrap ">
                <label
                  htmlFor="branch"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Branch
                </label>
                <Select
                  id="branch"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  isMulti
                  name="branch"
                  options={basicData?.branch_list}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => option?.branch_name}
                  value={basicData?.branch_list?.filter((p) =>
                    reportState?.otherReport?.currentBranchFilter?.includes(p._id)
                  )}
                  onChange={(value) => 
                    handleBranchFilter(value?.map((v) => v._id))
                    
                  }
                  menuPlacement="auto"
                />
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xxl-2">
              <div className="input-wrap ">
                <label
                  htmlFor="shop"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Shop
                </label>
                <Select
                  id="shop"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="shop"
                  options={basicData?.shop}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => `${option?.name} (${option?.location})`}
                  value={formik?.values?.shop}
                  onChange={(value) => formik.setFieldValue("shop", value)}
                  menuPlacement="auto"
                />
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xxl-2">
              <div className="input-wrap">
                <label
                  htmlFor="supplier"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Supplier
                </label>
                <Select
                  id="supplier"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="supplier"
                  options={basicData?.supplier}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => `${option?.name} (${option?.location})`}
                  value={formik?.values?.supplier}
                  onChange={(value) => formik.setFieldValue("supplier", value)}
                  menuPlacement="auto"
                />
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xxl-2">
              <div className="input-wrap ">
                <label
                  htmlFor="order_type"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Order Type
                </label>
                <Select
                  id="order_type"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="order_type"
                  options={basicData?.order_types}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.order_type}
                  onChange={(value) =>
                    formik.setFieldValue("order_type", value)
                  }
                  menuPlacement="auto"
                />
              </div>
            </div>

            <div className="col-auto pro-ms-auto">
          <button
            className="pro-btn pro-btn-primary lg"
            onClick={handleDownloadClick}
            //</div>disabled={
             // !(formik?.values?.shop_supplier?._id && formik?.values?.type?.id)
            //}
          >
            <span class="material-symbols-outlined x2">download</span>
            <span>Export</span>
          </button>
        </div>
          </div>
        </div>
        {branchReportData?.data?.data?.length > 0 ? (
          <div className="pro-mt-4">
            <CardHero data={cardData} />
            <Table
              multiSelect={false}
              data={branchReportData?.data?.data || []}
              uniqueID={"id"}
              fields={branchReportData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={reportState?.otherReport?.currentPageSize}
              assignable={false}
              editable={false}
              deletable={false}
              showCheckBox={false}
            />
            {branchReportData?.data?.data?.length > 0 && (
              <Pagination
                currentPage={reportState?.otherReport?.currentPage}
                totalPageCount={Math.ceil(
                  branchReportData?.data?.total_count /
                    reportState?.otherReport?.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </div>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default ShopReport;
