import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useRecheckStaffBilling from "./useStaffBilling";
import OrderColumn from "../../../Global/OrderColumn";
import Style from "../../staffBilling.module.scss";
import Filter from "./Filter";
import SubmitForm from "../SumbitForm";

export const RecheckStaffBilling = () => {
  const {
    filterShow,
    billingState,
    billingList,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    setFilterShow,
    handlePageSize,
    handlePagination,
    handleEditColumnsClick,
    handleGenerateEstimate,
    updateRecheckBillingTableHeadData,
  } = useRecheckStaffBilling();

  return (
    <>
      <HeadingGroup title={"Billing Recheck"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col-auto">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              showFilters={true}
              handleActionClick={handleEditColumnsClick}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showDateRange={false}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
              onClick={() => setFilterShow(true)}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={true}
          data={billingList?.data?.data || []}
          uniqueID={"_id"}
          fields={billingList?.data?.fields || []}
          showCheckBox={true}
          SortIcon={<FaSort />}
          getRow={getRow}
          handleSort={handleSort}
          perpage={10}
          assignText={"Submit"}
          assignIcon={
            <span className="material-symbols-outlined">fact_check</span>
          }
          handleAssign={handleGenerateEstimate}
          assignable={true}
          editable={false}
          deletable={false}
        />
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={billingList?.data?.fields}
              moduleId={billingList?.data?.module_id}
              updateData={updateRecheckBillingTableHeadData}
            />
          </div>
        </ModalLayout>
        {billingList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={billingState?.currentRecheckPage}
            totalPageCount={Math.ceil(
              billingList?.data?.total_count /
                billingState?.currentRecheckPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={filterShow}
          title={"Filter"}
          handleClose={() => {
            setFilterShow(false);
          }}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowForm={setFilterShow} />
        </OffCanvasLayout>
        <ModalLayout
          show={billingState?.showAddBillingModal}
          handleClose={handleGenerateEstimate}
          title={"Select One"}
        >
          <div className="pro-m-5">
            <SubmitForm handleClose={handleGenerateEstimate} />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default RecheckStaffBilling;
