import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetInvoiceHistoryListDataQuery,
  useUpdateInvoiceHistoryTableHeadDataMutation,
} from "../../../store/queries/Billing";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect, useRef, useState } from "react";
import { updateConfig } from "../../../store/slices/Billing/staffBillingSlice";
import { getInvoice, getReceipt } from "../Invoice/api";
import moment from "moment";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import { debounce } from "lodash";
import { subDays } from "date-fns";
import { getSupportingDoc } from "../api";
const useInvoices = () => {
  const invoiceRef2 = useRef(null);
  const receiptRef2 = useRef(null);
  const supportDocRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });
  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const queryState = useSelector((state) => state.staffBilling);
  const {
    data: queryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetInvoiceHistoryListDataQuery({
    per_page: queryState.currentPageSize,
    page: queryState.currentPage,
    sort_by: queryState.sortBy,
    sort_order: queryState.sortOrder,
    date: formattedDate,
    search: queryState.search,
  });

  const [updateInvoiceHistoryFields] =
    useUpdateInvoiceHistoryTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = queryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearchDebounced = debounce((value, dispatch) => {
    dispatch(
      updateConfig((state) => {
        state.search = value;
        state.currentPage = 1;
      })
    );
  }, 1000);

  const handleSearch = (e) => {
    handleSearchDebounced(e.target.value, dispatch);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.invoiceModal = !state.invoiceModal;
        state.slectedInvoice = e?.[0];
      })
    );
    dispatch(updateConfig((state) => (state.invoiceData = "")));
  };

  const handleInvoiceClick = (invoiceId) => {
    dispatch(updateConfig((state) => (state.clear = true)));
    getInvoice(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.clear = true;
            state.invoicePrintData = response?.data?.data;
          })
        );
        setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          const element = invoiceRef2?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Invoice" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
    getSupportingDoc(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.supportingPrintData = response?.data?.data;
          })
        );
        setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          const element = supportDocRef?.current;
          html2pdf(element, {
            margin: [17, 4, 17, 4],
            filename: `${
              "Supporting Doc" +
              moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 3,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleSlipClick = (invoiceId) => {
    getReceipt(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.receiptPrintData = response?.data?.data;
            state.clear = true;
          })
        );
        setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          const element = receiptRef2?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Receipt" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
    dispatch(
      updateConfig((state) => (state.invoiceHistoryFilter.date = dateArray))
    );
  };

  const cardData = [
    {
      label: "Total GLI",
      value: queryList?.data?.total_count ?? "--",
    },
    {
      label: "Daily Billed Amount",
      value: queryList?.data?.daily_total ?? "--",
    },
    {
      label: "Monthly Billed Amount",
      value: queryList?.data?.monthly_total ?? "--",
    },
    {
      label: "Total Billed Amount",
      value: queryList?.data?.sum_total ?? "--",
    },
  ];
  return {
    queryList,
    paginationOptions,
    isLoading,
    isFetching,
    tableFields,
    queryState,
    showEditModal,
    invoiceRef2,
    receiptRef2,
    date,
    supportDocRef,
    handleDateChange,
    handleEditColumnsClick,
    handlePageSize,
    handlePagination,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    handleEditAction,
    handleInvoiceClick,
    handleSlipClick,
    updateInvoiceHistoryFields,
    cardData,
  };
};

export default useInvoices;
