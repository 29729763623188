import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import Login from "../components/Auth/Login";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import Orders from "../components/Orders";
import Order from "../components/Orders/Order";
import Branches from "../components/Master/Branches";
import CertificatePrefix from "../components/Master/CertificatePrefix";
import Source from "../components/Master/Source";
import Shop from "../components/Master/Shop";
import Department from "../components/Master/Department";
import Role from "../components/Master/Role";
import Employee from "../components/Master/Employee";
import Process from "../components/Master/Process";
import Service from "../components/Master/Service";
import ItemType from "../components/Master/Itemtype/Index";
import Item from "../components/Master/Item";
import Packing from "../components/Master/Packing";
import Engraving from "../components/Master/Engraving";
import ItemGrading from "../components/Master/ItemGrading";
import Certificate from "../components/Master/Certificate";
import Report from "../components/Master/Report";
import Billing from "../components/Master/Billing";
import Dashboard from "../components/Master/Dashboard";
import Master from "../components/Master";
import AddRole from "../components/Master/Role/AddRole";
import Verifications from "../components/Verifications";
import Verification from "../components/Verifications/Verification/index";
import Employees from "../components/Employees";
import Staff from "../components/Employees/Staff";
import QC from "../components/Employees/QC";
// import PhotoUpload from "../components/Employees/PhotoUpload";
import Recheck from "../components/Orders/Recheck";
import EngravingSection from "../components/Employees/EngravingSection";
import EmployeePacking from "../components/Employees/Packing";
import OverViews from "../components/OverView";
import OverView from "../components/OverView/OverView";
import Billings from "../components/Billings";
import StaffBilling from "../components/Billings/StaffBilling";
import Invoice from "../components/Billings/Invoice";
import EngravingSectionRecheck from "../components/EmployeeRecheck/EngravingSectionRecheck";
import EmployeePackingRecheck from "../components/EmployeeRecheck/PackingRecheck";
import CVD from "../components/Employees/CVD";
// import PhotoUploadRecheck from "../components/EmployeeRecheck/PhotoUploadRecheck";
import Delivery from "../components/Employees/Delivery";
import DeliveryRecheck from "../components/EmployeeRecheck/DeliveryRecheck";
import StaffRecheck from "../components/Employees/StaffRecheck";
import Printing from "../components/Employees/Printing";
import QCRecheck from "../components/EmployeeRecheck/QCRecheck";
import CVDRecheck from "../components/EmployeeRecheck/CVDRecheck";
import OverViewRecheck from "../components/OverView/OverViewRecheck";
import PrintingRecheck from "../components/EmployeeRecheck/PrintingRecheck";
import Estimate from "../components/Billings/Estimate";
import Invoices from "../components/Billings/Invoices/Index";
import BillingRecheck from "../components/Billings/Recheck";
import RecheckStaffBilling from "../components/Billings/Recheck/RecheckStaffBilling";
import RecheckEstimate from "../components/Billings/Recheck/RecheckEstimate";
import RecheckInvoice from "../components/Billings/Recheck/RecheckInvoice";
import RecheckInvoices from "../components/Billings/Recheck/RecheckInvoices/Index";
import BillEstimate from "../components/Billings/BillEstimate";
import Home from "../components/Home";
import HomePage from "../components/Home/HomePage";
import Reports from "../components/Report/Reports";
import ReportMain from "../components/Report";
import PhotoSection from "../components/Employees/PhotoSection";
import PhotoSectionRecheck from "../components/EmployeeRecheck/PhotoSectionRecheck";
import PrintingReissue from "../components/EmployeeRecheck/PrintingReissue";
import BillingReissue from "../components/EmployeeRecheck/BillingReissue";
import CertificateList from "../components/EmployeeRecheck/BillingReissue/CertificateList";
import BillingReissueList from "../components/EmployeeRecheck/BillingReissue/BillingReissueList";
import ViewReport from "../components/Report/ViewReport";
import ShowReport from "../components/Report/ViewReport/ShowReport";
import DWSRReport from "../components/Report/DWSRReport";
import PercentageReport from "../components/Report/PercentageReport";
import VerifyCertificate from "../components/VerifyCertificate";
import GLIReport from "../components/Report/GLIReport";
import GLIDetails from "../components/Report/GLIReport/GLIDetails";
import OtherReport from "../components/Report/OtherReport";
import EmployeeDetail from "../components/Report/OtherReport/EmployeeReport/EmployeeDetail";
import InvoiceNumberFormat from "../components/Master/InvoiceNumberFormat";
import Reassign from "../components/Orders/Reassign";
import PhotoSectionReissue from "../components/EmployeeRecheck/PhotoSectionReissue";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/dhc_certificate" element={<VerifyCertificate />}></Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>
        <Route path="/orders" element={<Orders />}>
          <Route path="" element={<Order />}></Route>
          <Route path="recheck" element={<Recheck />}></Route>
          <Route path="reassign" element={<Reassign />}></Route>
        </Route>
        <Route path="/verification" element={<Verifications />}>
          <Route path="" element={<Verification />}></Route>
        </Route>
        <Route path="/report" element={<ReportMain />}>
          <Route path="" element={<Reports />}></Route>
          <Route path="dwsr" element={<DWSRReport />}></Route>
          <Route path="percentage" element={<PercentageReport />}></Route>
          <Route path="other" element={<OtherReport />}></Route>
          <Route path="other/detail" element={<EmployeeDetail />}></Route>
          <Route path="gli" element={<GLIReport />}></Route>
          <Route path="gli/detail" element={<GLIDetails />}></Route>
        </Route>
        <Route path="/view-report" element={<ViewReport />}>
          <Route path="" element={<ShowReport />}></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="qc" element={<Staff />}></Route>
          <Route path="qc-recheck" element={<StaffRecheck />}></Route>
          <Route path="qc/qc-work" element={<QC />}></Route>
          <Route
            path="qc-recheck/qc-work-recheck"
            element={<QCRecheck />}
          ></Route>
        </Route>
        <Route path="orders" element={<Employees />}>
          <Route path="metal-qc" element={<Staff />}></Route>
          <Route path="metal-qc-recheck" element={<StaffRecheck />}></Route>
          <Route path="metal-qc/metal-qc-work" element={<QC />}></Route>
          <Route
            path="metal-qc-recheck/metal-qc-work-recheck"
            element={<QCRecheck />}
          ></Route>
        </Route>
        <Route path="orders" element={<Employees />}>
          <Route path="old-gold-qc" element={<Staff />}></Route>
          <Route path="old-gold-qc-recheck" element={<StaffRecheck />}></Route>
          <Route path="old-gold-qc/old-gold-qc-work" element={<QC />}></Route>
          <Route
            path="old-gold-qc-recheck/old-gold-qc-work-recheck"
            element={<QCRecheck />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="final-qc" element={<Staff />}></Route>
          <Route path="final-qc-recheck" element={<StaffRecheck />}></Route>
          <Route path="final-qc/final-qc-work" element={<QC />}></Route>
          <Route
            path="final-qc-recheck/final-qc-work-recheck"
            element={<QCRecheck />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="cvd" element={<Staff />}></Route>
          <Route path="cvd-recheck" element={<StaffRecheck />}></Route>
          <Route path="cvd/cvd-work" element={<CVD />}></Route>
          <Route
            path="cvd-recheck/cvd-work-recheck"
            element={<CVDRecheck />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="engraving" element={<Staff />}></Route>
          <Route path="engraving-recheck" element={<StaffRecheck />}></Route>
          <Route
            path="engraving/engraving-work"
            element={<EngravingSection />}
          ></Route>
          <Route
            path="engraving-recheck/engraving-work-recheck"
            element={<EngravingSectionRecheck />}
          ></Route>
        </Route>

        {/* <Route path="orders" element={<Employees />}>
          <Route path="photo-upload" element={<Staff />}></Route>
          <Route path="photo-upload-recheck" element={<StaffRecheck />}></Route>
          <Route
            path="photo-upload/photo-upload-work"
            element={<PhotoUpload />}
          ></Route>
          <Route
            path="photo-upload-recheck/photo-upload-work-recheck"
            element={<PhotoUploadRecheck />}
          ></Route>
        </Route> */}
        <Route path="orders" element={<Employees />}>
          <Route path="photo/photo-section" element={<PhotoSection />}></Route>
          <Route
            path="photo/photo-section-recheck"
            element={<PhotoSectionRecheck />}
          ></Route>
          <Route
            path="photo/photo-section-reissue"
            element={<PhotoSectionReissue />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="printing/printing-work" element={<Printing />}></Route>
          <Route
            path="printing-recheck/printing-work-recheck"
            element={<PrintingRecheck />}
          ></Route>
          <Route
            path="printing-reissue/printing-work-reissue"
            element={<PrintingReissue />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="packing" element={<Staff />}></Route>
          <Route path="packing-recheck" element={<StaffRecheck />}></Route>
          <Route
            path="packing/packing-work"
            element={<EmployeePacking />}
          ></Route>
          <Route
            path="packing-recheck/packing-work-recheck"
            element={<EmployeePackingRecheck />}
          ></Route>
        </Route>

        <Route path="orders" element={<Employees />}>
          <Route path="delivery/delivery-work" element={<Delivery />}></Route>
          <Route
            path="delivery-recheck/delivery-work-recheck"
            element={<DeliveryRecheck />}
          ></Route>
        </Route>
        <Route path="/billing" element={<Billings />}>
          <Route path="" element={<StaffBilling />}></Route>
          <Route path="estimate" element={<Estimate />}></Route>
          <Route path="estimate/invoice" element={<Invoice />}></Route>
          <Route path="invoice-history" element={<Invoices />}></Route>
          <Route path="bill-estimate" element={<BillEstimate />}></Route>
          <Route path="bill-estimate/invoice" element={<Invoice />}></Route>

          <Route path="billing-recheck" element={<BillingRecheck />}>
            <Route path="" element={<RecheckStaffBilling />}></Route>
            <Route path="estimate" element={<RecheckEstimate />}></Route>
            <Route path="estimate/invoice" element={<RecheckInvoice />}></Route>
            <Route path="invoice-history" element={<RecheckInvoices />}></Route>
          </Route>
          <Route path="reissue" element={<BillingReissue />}>
            <Route path="" element={<BillingReissueList />} />
            <Route path="certificates-list" element={<CertificateList />} />
          </Route>
        </Route>
        <Route path="/overview" element={<OverViews />}>
          <Route path="" element={<OverView />}></Route>
          <Route path="recheck" element={<OverViewRecheck />}></Route>
        </Route>
        <Route path="/home" element={<Home />}>
          <Route path="" element={<HomePage />}></Route>
        </Route>
        <Route path="/master" element={<Master />}>
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="branches" element={<Branches />}></Route>
          <Route path="source" element={<Source />}></Route>a
          <Route path="shop" element={<Shop />}></Route>
          <Route path="department" element={<Department />}></Route>
          <Route path="role" element={<Role />}></Route>
          <Route path="role/update" element={<AddRole />}></Route>
          <Route path="employee" element={<Employee />}></Route>
          <Route path="process" element={<Process />}></Route>
          <Route path="service" element={<Service />}></Route>
          <Route path="item-type" element={<ItemType />}></Route>
          <Route path="item" element={<Item />}></Route>
          <Route path="packing" element={<Packing />}></Route>
          <Route path="engraving" element={<Engraving />}></Route>
          <Route path="item-grading" element={<ItemGrading />}></Route>
          <Route path="certificate" element={<Certificate />}></Route>
          <Route
            path="invoice-number-format"
            element={<InvoiceNumberFormat />}
          ></Route>
          <Route
            path="certificate-prefix"
            element={<CertificatePrefix />}
          ></Route>
          <Route path="report" element={<Report />}></Route>
          <Route path="billing" element={<Billing />}></Route>
        </Route>
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
