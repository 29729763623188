import { useSelector } from "react-redux";
import { useGetOtherReportBranchTableDataQuery } from "../../../../store/queries/Reports";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Reports/reportsSlice";
import { useFormik } from "formik";
import { useState } from "react";
import { subDays } from "date-fns";

const useBranchReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.reports);

  const formik = useFormik({
    initialValues: {
      branch: "",
      order_type: "",
    },
  });
  const { data: branchReportData = {}, isFetching } =
    useGetOtherReportBranchTableDataQuery(
      {
        type: 2,
        date: formattedDate,
        per_page: reportState?.otherReport?.currentPageSize,
        page: reportState?.otherReport?.currentPage,
        sort_by: reportState?.otherReport?.sortBy,
        sort_order: reportState?.otherReport?.sortOrder,
        search: reportState?.otherReport?.search,
        branch_id: reportState?.otherReport?.currentTempBranchFilter,
        order_type: formik?.values?.order_type?.id,
      },
      {
        skip: !(reportState?.otherReport?.currentTempBranchFilter?.[0] && formik?.values?.order_type),
      }
    );

  const cardData = [
    {
      label: "Total GLI",
      value: branchReportData?.data?.total_gli ?? "--",
    },
    {
      label: "Total certificates",
      value: branchReportData?.data?.total_item_details_count ?? "--",
    },
    {
      label: "Completed",
      value: branchReportData?.data?.total_completed_count ?? "--",
    },
    {
      label: "Rejected",
      value: branchReportData?.data?.total_rejected_count ?? "--",
    },
    {
      label: "Marked",
      value: branchReportData?.data?.total_marked_count ?? "--",
    },
    {
      label: "Process Time",
      value: branchReportData?.data?.total_process_time ?? "--",
    },
    {
      label: "People Worked",
      value: branchReportData?.data?.total_person_worked ?? "--",
    },
    {
      label: "Avarage Time Worked(GLI)",
      value: branchReportData?.data?.avg_time_worked_gli ?? "--",
    },
    {
      label: "Avarage Time Worked(Person)",
      value: branchReportData?.data?.avg_time_worked_person ?? "--",
    },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.search = e.target.value;
        state.otherReport.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPageSize = page_size;
        state.otherReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.otherReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortOrder =
            reportState.otherReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortBy = label;
          state.otherReport.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      service: (field, data) => <p className="pro-mb-0">{data[field]?.name}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleBranchFilter = (value) => {
    let temp = [];
    temp[0] = value?._id; 
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = 1;
        state.otherReport.currentTempBranchFilter = temp;
      })
    );
  };

  return {
    date,
    formik,
    cardData,
    isFetching,
    reportState,
    paginationOptions,
    branchReportData,
    getRow,
    handleSort,
    handlePageSize,
    handlePagination,
    handleSearch,
    handleDateChange,
    handleBranchFilter
  };
};

export default useBranchReport;
