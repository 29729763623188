import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./delivery.module.scss";
import OrderCardListing from "../../Global/OrderCardListing";
import DeliveryCertificate from "../Delivery/DeliveryCertificate";
import useDelivery from "./useDelivery";
import DeliverySlip from "./DeliverySlip/DeliverySlip";
import DeliveryChallanPrint from "./DeliveryChellan/DeliveryChellan";

const Delivery = () => {
  const {
    gliNumber,
    showDetail,
    activeCard,
    deliveryData,
    totalLength,
    isLoading,
    certificateListData,
    slipRef,
    chellanRef,
    apiStatus,
    setCertificateList,
    handleLoadMore,
    handleSearchInput,
    setIsLoading,
    handleSearch,
    handleChange,
    deliveryState,
  } = useDelivery();

  return (
    <>
      <div className="row pro-mb-4 gx-2">
        <div className="col-auto">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleSearchInput}
            showFilters={true}
            showActions={false}
            searchInputProps={{ value: deliveryState?.search }}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
          />
        </div>
        <div className={`col-auto`}>
          <button
            className="pro-btn pro-btn-primary lg"
            onClick={handleSearch}
            type="button"
          >
            <span className="material-symbols-outlined">search</span>
            Search
          </button>
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-4 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                isLoading={isLoading}
                data={deliveryData}
                handleChange={handleChange}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
                activeCard={activeCard}
                apiStatus={apiStatus}
                refBottom
              />
            </div>
          </div>
          {showDetail === true && (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <DeliveryCertificate
                  gliNumber={gliNumber}
                  states={deliveryData}
                  certificateList={certificateListData}
                  slipRef={slipRef}
                  chellanRef={chellanRef}
                  setCertificateList={setCertificateList}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          opacity: "0",
          visibility: "hidden",
          height: "0",
          width: "0",
        }}
      >
        <DeliverySlip slipRef={slipRef} />
        <DeliveryChallanPrint chellanRef={chellanRef} />
      </div>
    </>
  );
};

export default Delivery;
