import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCardAssignedList,
  getCardPendingList,
  updateConfig,
} from "../../../store/slices/Staff/recheckStaffSlice";

const useRecheckStaffWindow = () => {
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.recheckStaff);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    dispatch(
      getCardPendingList({
        page: 1,
        per_page: 10,
        date: staffState.date,
        item_type: staffState.item_type,
        shop: staffState.shop,
        status: staffState.status,
        process_slug: slug,
      })
    );
    dispatch(
      getCardAssignedList({
        page: 1,
        per_page: 10,
        date: staffState.date,
        item_type: staffState.item_type,
        shop: staffState.shop,
        status: staffState.status,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    staffState.date,
    staffState.item_type,
    staffState.shop,
    staffState.status,
    slug,
  ]);

  const handleFilter = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilter = !state.showFilter;
      })
    );
  };

  const handleCardClick = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.gliData.name = item?.gli_number;
        state.gliData.id = item?._id;
        state.gliData.shop = item?.shop;
      })
    );
    if (key === "Pending") {
      // dispatch(getTableData(item?._id));
      dispatch(
        updateConfig((state) => {
          state.showAssignedTable = false;
          state.showTable = true;
        })
      );
    } else if (key === "Assigned") {
      dispatch(
        updateConfig((state) => {
          // state.certificateData = response?.data?.data;
          state.showTable = false;
          state.showAssignedTable = true;
        })
      );
    }
  };
  const handleStartClick = () => {
    switch (localStorage?.getItem("slug")) {
      case "cvd_rft":
        navigate("cvd-work-recheck");
        break;
      case "qc":
        navigate("qc-work-recheck");
        break;
      case "metal_qc":
        navigate("metal-qc-work-recheck");
        break;
      case "og_qc":
        navigate("old-gold-qc-work-recheck");
        break;
      case "final_qc":
        navigate("final-qc-work-recheck");
        break;
      case "photo_section":
        navigate("photo-upload-work-recheck");
        break;
      case "engraving":
        navigate("engraving-work-recheck");
        break;
      case "billing":
        navigate("billing");
        break;
      case "packing":
        navigate("packing-work-recheck");
        break;
      case "printing":
        navigate("printing-work-recheck");
        break;
      case "delivery":
        navigate("delivery-work-recheck");
        break;
      default:
        navigate("/");
    }
  };

  const handleSearchInput = (e) => {
    const searchValue = e?.target?.value;
    dispatch(updateConfig((state) => (state.search = searchValue)));
  };

  const handleSearch = () => {
    const searchValue = staffState?.search;

    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.cardData.Assigned = [];
      })
    );
    dispatch(
      getCardPendingList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    dispatch(
      getCardAssignedList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
  };

  return {
    activeCard,
    staffState,
    handleCardClick,
    handleFilter,
    handleSearchInput,
    handleStartClick,
    handleSearch,
    apiStatus: {
      Pending: staffState.cardPendingStatus,
      Assigned: staffState.cardAssignedStatus,
    },
  };
};

export default useRecheckStaffWindow;
