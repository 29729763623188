import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getDeliveryListData,
} from "../../../store/slices/Delivery/deliverySlice";
import { debounce } from "../../../utils/hooks/useDebounce";
import { startTimer } from "../api";
import { toast } from "react-toastify";

const useDelivery = () => {
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const deliveryState = useSelector((state) => state.delivery);
  const deliveryData = deliveryState.deliverySectionData;
  const [showDetail, setShowDetail] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const slipRef = useRef(null);
  const chellanRef = useRef(null);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  // useEffect(() => {
  //   let params = {
  //     page: pendingPage,
  //     per_page: deliveryState.currentPageSize,
  //     // search: deliveryState.search,
  //     process_slug: slug,
  //   };
  //   dispatch(getDeliveryListData(params));
  //   // eslint-disable-next-line
  // }, [pendingPage, deliveryState.currentPageSize, slug]);

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.deliverySectionData.Pending = [];
        state.search = "";
      })
    );
    dispatch(
      getDeliveryListData({
        page: 1,
        per_page: 10,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (pendingPage !== 1) {
      if (
        deliveryState.deliverySectionData.Pending?.length <=
        deliveryState.total?.Pending
      ) {
        dispatch(
          getDeliveryListData({
            page: pendingPage,
            per_page: 10,
            process_slug: slug,
            search: deliveryState.search,
          })
        );
      } else if (
        deliveryState.deliverySectionData.Pending?.length >
        deliveryState.total?.Pending
      ) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [pendingPage]);

  const handleSearchInput = (searchValue) => {
    dispatch(
      updateConfig((state) => {
        state.search = searchValue?.target?.value;
      })
    );
  };
  const handleSearch = () => {
    const searchValue = deliveryState?.search;

    setPendingPage(1);
    dispatch(
      updateConfig((state) => {
        state.deliverySectionData.Pending = [];
        state.currentPage = 1;
      })
    );
    dispatch(
      getDeliveryListData({
        page: 1,
        per_page: 10,
        search: searchValue,
        process_slug: slug,
      })
    );
  };

  const startTimerDelivery = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleChange = (item, key) => {
    dispatch(
      updateConfig((state) => {
        state.selectedCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    startTimerDelivery(item);
    setActiveCard({ id: item?._id, key: "Pending" });
    if (key === "Pending") {
      setShowDetail(true);
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
        })
      );
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      deliveryData?.Pending?.length <= deliveryState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
      dispatch(
        updateConfig((state) => {
          state.currentPage = pendingPage + 1;
        })
      );
    }
  };
  return {
    gliNumber,
    slipRef,
    chellanRef,
    showDetail,
    activeCard,
    deliveryData,
    isLoading,
    totalLength: {
      Pending: deliveryState.total.Pending,
    },
    apiStatus: {
      Pending: deliveryState.deliverySectionDataStatus,
    },
    // certificateListData,
    handleSearch,
    handleSearchInput,
    handleChange,
    handleLoadMore,
    setIsLoading,
    deliveryState,
    // setCertificateList,
  };
};

export default useDelivery;
