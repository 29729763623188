import { useEffect, useRef, useState } from "react";
import {
  useGetBillEstimateDataQuery,
  useUpdateBillEstimateTableHeadDataMutation,
} from "../../../store/queries/Billing";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Billing/staffBillingSlice";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import {
  getEstimate,
  getEstimateBill,
  getEstimateInvoice,
  getRegenerateEstimate,
} from "../Invoice/api";
import { subDays } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { checkGenerateInvoice } from "../api";

const useBillEstimate = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const reduxState = useSelector((state) => state.staffBilling);
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });
  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const invoiceRef = useRef(null);
  const estimateRef = useRef(null);
  const estimateReRef = useRef(null);
  const navigate = useNavigate();
  const queryState = reduxState?.BillEstimate;
  const {
    data: queryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBillEstimateDataQuery({
    per_page: queryState.currentPageSize,
    page: queryState.currentPage,
    sort_by: queryState.sortBy,
    sort_order: queryState.sortOrder,
    search: queryState.search,
    date: formattedDate,
    active_tab: activeTab === "pending" ? 1 : 2,
  });
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [updateBillEstimateFields] =
    useUpdateBillEstimateTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  useEffect(() => {
    const updateState = () => {
      return new Promise((resolve) => {
        dispatch(
          updateConfig((state) => {
            state.BillEstimate.activeTab = activeTab;
            state.clearSelection = true;
            state.BillEstimate.clear = true;
          })
        );
        resolve();
      });
    };
    updateState().then(() => {
      dispatch(
        updateConfig((state) => {
          state.BillEstimate.clear = false;
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      service: (feild, data) => (
        <ul>
          {data[feild]?.map(({ name }, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.BillEstimate.currentPageSize = page_size;
        state.BillEstimate.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.BillEstimate.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.BillEstimate.currentPage = 1;
          state.BillEstimate.sortOrder =
            queryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.BillEstimate.currentPage = 1;
          state.BillEstimate.sortBy = label;
          state.BillEstimate.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearchDebounced = debounce((value, dispatch) => {
    dispatch(
      updateConfig((state) => {
        state.BillEstimate.search = value;
        state.BillEstimate.currentPage = 1;
      })
    );
  }, 1000);

  const handleSearch = (e) => {
    handleSearchDebounced(e.target.value, dispatch);
  };

  function generateAll(estimate_id) {
    getEstimateInvoice({ estimate_id }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig(
            (state) => (state.invoicePrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = invoiceRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Invoice" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          });
        }, 500);
      } else if (response?.status_code === 403) {
        toast.error(response?.message);
      } else {
        toast.error("Something went wrong to print");
      }
    });
    getEstimateBill({ estimate_id }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig(
            (state) => (state.supportingPrintData = response?.data?.data)
          )
        );
        handleClear();
        setTimeout(() => {
          const element = estimateRef?.current;
          html2pdf(element, {
            margin: [5, 0, 0, 0],
            filename: `${
              "Supporting doc" +
              moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  }

  const handleAssign = (invoiceId) => {
    handleClear();
    let estimate_id = invoiceId;

    if (estimate_id?.length > 1) {
      checkGenerateInvoice({
        estimate_id,
      }).then((resp) => {
        if (resp?.data?.status_code === 200) {
          if (resp?.data?.data?.show_warning) {
            // Show the modal based on API response
            dispatch(
              updateConfig((state) => {
                state.BillEstimate.warningModal = true;
              })
            );

            // Store the estimate_id to use when the user confirms the modal
            dispatch(
              updateConfig((state) => {
                state.BillEstimate.pendingEstimateId = estimate_id;
              })
            );
          } else {
            generateAll(estimate_id);
          }
        } else {
          toast.error("Some server issue occurred");
        }
      });
    } else {
      generateAll(estimate_id);
    }
  };

  const handlePrintClick = (e) => {
    let id = e?.[0];
    getEstimate(id).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.estimatePrintData = response?.data?.data)
          )
        );
        handleClear();
        setTimeout(() => {
          const element = estimateReRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Estimate" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const handleClear = () => {
    dispatch(updateConfig((state) => (state.BillEstimate.clear = true)));
    const timeOut = setTimeout(() => {
      dispatch(updateConfig((state) => (state.BillEstimate.clear = false)));
      clearTimeout(timeOut);
    }, 1000);
  };

  const handleRegenerate = (e) => {
    if (e?.length == 1) {
      let id = e?.[0];
      getRegenerateEstimate(id).then((response) => {
        if (response?.data?.status_code === 200) {
          localStorage?.setItem(
            "invoice_id",
            response?.data?.data?.data?.estimate_id
          );
          dispatch(
            updateConfig((state) => {
              state.invoiceData = response?.data?.data?.data;
              state.estimateModal = false;
              state.isPending = false;
            })
          );
          toast.success(response?.data?.message);
          navigate("invoice");
        } else if (response?.status_code === 403) {
          toast.error(response?.message);
          dispatch(updateConfig((state) => (state.isPending = false)));
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            toast.error(errors[field]?.[0]);
          });
          dispatch(updateConfig((state) => (state.isPending = false)));
        } else {
          toast.error("Something went wrong");
          dispatch(updateConfig((state) => (state.isPending = false)));
        }
      });
    } else {
      toast.warning("Please select only one!");
    }
  };

  const handleCNFModal = () => {
    // Close the modal
    dispatch(
      updateConfig((state) => {
        state.BillEstimate.warningModal = false;
      })
    );

    // Retrieve the stored estimate_id and trigger generateAll
    const estimate_id = queryState.pendingEstimateId;
    if (estimate_id) {
      generateAll(estimate_id);
    }
  };

  const handleCancelCNFModal = () => {
    dispatch(
      updateConfig((state) => {
        state.BillEstimate.warningModal = false;
        state.BillEstimate.pendingEstimateId = null; // Clear the stored estimate_id
      })
    );
  };

  const navigation = [
    {
      label: "Pending",
      title: "Pending",
      link: `/billing/bill-estimate?activeTab=pending`,
      active: queryState?.activeTab === "pending",
    },
    {
      label: "Completed",
      title: "Completed",
      link: `/billing/bill-estimate?activeTab=completed`,
      active: queryState?.activeTab === "completed",
    },
  ];

  return {
    tabNavs: navigation,
    paginationOptions,
    queryList,
    isLoading,
    isFetching,
    tableFields,
    estimateReRef,
    showEditModal,
    queryState,
    activeTab,
    invoiceRef,
    estimateRef,
    date,
    handleDateChange,
    handleSearch,
    handleSort,
    handlePageSize,
    handleAssign,
    handlePrintClick,
    handlePagination,
    updateBillEstimateFields,
    handleEditColumnsClick,
    refetch,
    getRow,
    handleRegenerate,
    handleCNFModal,
    handleCancelCNFModal,
  };
};

export default useBillEstimate;
