import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  per_page: 10,
  selectedProfiles: [],
  PendingTotal: "",
  ApprovedTotal: "",
  currentPage: 1,
  search: "",
  from_date: "",
  to_date: "",
  percentageReport: {
    currentBranchFilter: [],
    currentPageSize: 10,
    currentPage: 1,
    search: "",
  },
  GLIReport: {
    currentBranchFilter: [],
    currentTempBranchFilter: [],
    currentPageSize: 10,
    currentPage: 1,
    search: "",
    details: {
      currentPageSize: 10,
      currentPage: 1,
      search: "",
      activeTab: "Passed",
    },
  },
  otherReport: {
    currentBranchFilter: [],
    currentPageSize: 10,
    currentPage: 1,
    search: "",
    activeTab: "",
    details: {
      currentPageSize: 10,
      currentPage: 1,
      search: "",
    },
  },
};
const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = reportsSlice.actions;

export default reportsSlice.reducer;
